@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Styles */
.offer {
  background: #283593;
  padding: 5px;
  color: #fff;
  font-size: 14px;
}

.offer>.container>svg {
  font-size: 20px;
  margin-right: 5px;
}

.icon-dropdown {
  width: 35px;
}

.dropdown-item {
  padding: 15px !important;
}

.description {
  font-size: 14px;
}

.dropdown-menu.show {
  border-radius: 0px;
  border: none !important;
  box-shadow: 0px 0px 2px #00000012;
  padding: 15px !important;
}

section {
  background: linear-gradient(75deg, #283593 10%, #7100b9);
  padding: 20px;
  color: #fff;
}

.carousel-caption {
  position: relative !important;
  right: 15%;
  bottom: 0 !important;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: justify !important;
}

.carousel-control-prev,
.carousel-control-next {
  width: auto !important;
}

.list-group-item {
  position: relative;
  display: flex !important;
  align-items: center;
  gap: 4px;
  padding: 5px 0 !important;
  color: #ffffff !important;
  text-decoration: none;
  background-color: transparent !important;
  border: none !important;
  font-size: 13px;
}

.carousel-indicators {
  display: none !important;
}

.h7,
h7 {
  font-size: 0.9rem;
  display: block;
}

.card-body {
  padding: 40px !important;
}

.button-outline {
  padding: 10px 24px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #283593 !important;
  color: #283593 !important;
  background: transparent !important;
  transition: 0.5s !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
}

.button-outline:hover {
  border: 1px solid #283593 !important;
  color: #fff !important;
  background: linear-gradient(75deg, #283593 10%, #7100b9) !important;
  transition: 0.5s !important;
}

.card {
  position: relative;
  line-height: 24px !important;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  border: none !important;
}

.border-outline {
  border: 1px solid #283593 !important;
}

.plan-title {
  background-color: #283593;
  color: #fff;
  padding: 5px 11px 5px 12px;
  position: absolute;
  left: 18px;
  top: -13px;
  text-transform: uppercase;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
}

.bg-light {
  background: #fff !important;
  background-color: #fff !important;
}

.box-shadow-sm {
  box-shadow: 0px 0px 3px 2px #00000024;
}

.imageVendors {
  width: 122.6px;
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  gap: 10px;
  margin: 10px;
  cursor: pointer !important;
}

.imageVendors img {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
}

.bg-secondary-s {
  background-color: #f4f6f9 !important;
}

.bg-secondary-t {
  background: black !important;
}

.padding-nav-item {
  padding: 5px 0 !important;
}

.bg-secondary-n {
  background: #283593;
}

span.d-inline.badge {
  font-size: 9px !important;
  position: relative;
  top: -2px;
  border-radius: 3px !important;
}

.flex-column-break {
  width: max-content !important;
  white-space: normal;
  word-break: break-word;
  max-width: 280px;
}

.fs-7 {
  font-size: 14px !important;
  color: #d3d3d4 !important;
}

.fs-71 {
  font-size: 14px !important;
}

.fs-72 {
  font-size: 12px !important;
}

.fs-7:hover {
  color: #283593 !important;
  opacity: 1 !important;
}

code {
  font-size: .875em;
  color: #283593 !important;
  word-wrap: break-word;
}

.btn-prismy {
  background: #ffffff !important;
  border: 1px solid #302f97 !important;
  color: #000000a6 !important;
}

.btn-prismy:hover {
  background: #302f97 !important;
  border: 1px solid #302f97 !important;
  color: #fff !important;
}

.fw-color-blue {
  color: #283593 !important;
}

.fw-bt-outline {
  padding: 5px 20px !important;
  width: max-content;
  border: 1px solid #283593 !important;
  border-radius: 5px;
  display: inline;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  font-size: 12px !important;
}

.color-listgroup {
  color: #849fbb !important;
}

.iconCheck {
  font-size: 20px;
  color: #00a4a6;
  display: inline-block;
}

.button-outline {
  cursor: pointer !important;
}

.card-header-servidor {
  background: url(https://www.banahosting.com/images/domain_bg.jpg) !important;
  background-position: center !important;
  width: 100%;
  height: auto;
  border: none !important;
  color: #fff !important;
  padding: 20px 50px !important;
  font-size: 14px !important;
}

.card-footer {
  background: transparent !important;
}

.table-host {
  min-width: 800px;
  border-bottom: 1px solid #dee2e6;
  margin: 0;
}

.table-host>thead {
  display: flex;
  justify-content: space-between;
}

.table-host thead th {
  font-size: 14px;
  border-top: 0 !important;
  border-bottom: 0 !important;
  text-transform: uppercase;
  background: transparent !important;
  color: #fff !important;
  box-shadow: none !important;
}

.bg-primary {
  background-color: #283593 !important;
}

.table>:not(caption)>*>* {
  text-align: center;
}

.tablebody>tr>td {
  padding: 20px;
  font-weight: 500;
  font-size: 13px !important;
}

.price-td {
  color: #283593 !important;
}

.button-buy {
  border: 1px solid #283593 !important;
  color: #fff !important;
  background: #283593 !important;
  transition: 0.5s !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  padding: 10px 24px !important;
}

.table>tbody {
  vertical-align: middle !important;
}

.ullist {
  font-size: 14px !important;
}

.ullist>li>b {
  font-weight: 600 !important;
}

.p-5-carousel {
  padding: 3rem !important;
}

/* */
@media only screen and (max-width: 1399px) {

  .homecarousel>.col {
    width: 10% !important;
  }

  .carousel>.carousel-indicators,
  .carousel>.carousel-control-prev,
  .carousel>.carousel-control-next {
    display: none !important;
  }

  .p-5-carousel {
    padding: 0 !important;
  }

  .px-15 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .px-100 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .carousel-caption {
    position: absolute;
    right: 0 !important;
    bottom: 1.25rem;
    left: 0 !important;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start !important;
  }

  .products-h {
    display: flex;
    flex-direction: column;
  }

  /* .carousel-item>.row>.col:last-child {
    display: none !important;
  } */

  .col-md-6 {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    flex: auto !important;
  }

  .table>thead {
    vertical-align: bottom;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }

  tbody.tablebody {
    font-size: 12px;
  }

  .table>:not(caption)>*>* {
    padding: 3px !important;
  }

  .ocultar-cl {
    display: none !important;
  }

  .producthome {
    width: 100% !important;
  }
}

@media (max-width: 980px) {
  .homecarousel>.col {
    width: 50% !important;
  }

  .carousel-item>.row>.col:last-child {
    display: none !important;
  }

  .col-md-4 {
    width: 100% !important;
  }
}

/* Celular */
@media (max-width: 600px) {

  .carousel>.carousel-indicators,
  .carousel>.carousel-control-prev,
  .carousel>.carousel-control-next {
    display: none !important;
  }

  .p-5-carousel {
    padding: 0 !important;
  }

  .px-15 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .px-100 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .carousel-caption {
    position: absolute;
    right: 0 !important;
    bottom: 1.25rem;
    left: 0 !important;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start !important;
  }

  .products-h {
    display: flex;
    flex-direction: column;
  }

  .carousel-item>.row>.col:last-child {
    display: none !important;
  }

  .col-md-6 {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    flex: auto !important;
  }

  .table>thead {
    vertical-align: bottom;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }

  tbody.tablebody {
    font-size: 12px;
  }

  .table>:not(caption)>*>* {
    padding: 3px !important;
  }

  .ocultar-cl {
    display: none !important;
  }

  tr {
    font-size: 13px !important;
  }

  .tablebody>tr>td {
    font-size: 11px !important;
    line-height: normal;
  }

  .button-buy {
    padding: 5px 4px !important;
  }
}

.image-header {
  width: auto;
  height: 170px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pricetag {
  background: #d4e1f4;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 15px;
  color: #283593;
  font-weight: 500;
  font-size: 14px;
}

.servicecard {
  height: 222px;
  overflow: hidden;
  padding-bottom: 40px !important;
  margin-bottom: 20px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.req-success {
  padding: 5px;
  border-radius: 4px !important;
  float: right;
  position: relative;
  margin: 10px;
  background: #283593 !important;
  border: 1px solid #283593 !important;
  box-shadow: 0 0 4px 1px #00000026;
}

.hoverCard:hover {
  background: #28349329;
  color: #283593;
  transition: 1s;
}

.hoverCard {
  transition: 1s;
  cursor: pointer;
}

.popover-btn {
  border: none;
  background-color: transparent;
  font-size: 10px;
  border: 1px solid #849fbb;
  border-radius: 50%;
  padding: 1px 4.5px;
  vertical-align: middle;
  color: #849fbb;
  line-height: 1.2;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000 !important;
  text-decoration: none !important;
  background-color: var(--bs-dropdown-link-hover-bg) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background: rgb(40 53 147) !important;
  background-color: rgb(40 53 147) !important;
}

span.req-success.badge.bg-primary {
  background-color: #283593 !important;
}

.rfm-marquee-container {
  overflow: hidden !important;
}

.btn-verplanos {
  border-radius: 4px !important;
  overflow: hidden;
  min-width: 160px;
  background-color: transparent !important;
  text-transform: uppercase;
  font-size: 14px !important;
  font-weight: 600 !important;
  position: relative;
  transition: all .4s ease !important;
  cursor: pointer !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
}

.btn-verplanos:hover {
  color: #283593 !important;
  background: #fff !important;
  transform: translateY(-.1875rem);
  transition: all .4s ease !important;
  box-shadow: -1px 4px 4px 0px #ffffff59;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex !important;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 5px !important;
  height: 5px !important;
  border-radius: 100% !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.productCarousel>.carousel>.carousel-indicators,
.productCarousel>.carousel>.carousel-control-prev,
.productCarousel>.carousel>.carousel-control-next {
  display: none !important;
}

.certified {
  color: #ffffffc9;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  word-break: auto-phrase;
  width: 100px;
  text-decoration: none !important;
  transition: 1s !important;
}

.certified:hover {
  transform: translateY(-0.1875rem);
  transition: 1s !important;
  text-shadow: -2px 1px 2px #0000004f !important;
}

.api-popover {
  margin-left: 5px !important;
  border: 1px solid #283d96 !important;
  color: #283d96 !important;
  font-weight: 600 !important;
  margin-top: -2px !important;
}

@keyframes fade {
  from {
    opacity: 0.5;
  }
}

.blinking {
  animation: fade 1s infinite alternate;
}

.nameHosting {
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: center;
}

.buttonHosting {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.bg-primarys {
  background: #283593 !important;
}

.list-group-item>span>b {
  color: black;
}

.tooltip-inner {
  max-width: 300px !important;
}

tr {
  font-size: 15px !important;
}

.processtd {
  width: 406px;
}

.nametd {
  width: 142.44px;
}

.ramtd {
  width: 260.53px;
}

.larguratd {
  width: 161.14px;
}

.precotd {
  width: 168.55px;
}

.configurartd {
  width: 181.72px;
}

.tabletwo>td {
  padding: 10px !important;
  font-weight: 500;
  line-height: normal;
}

.tabletwo>td>p {
  margin: 0 !important;
  padding: 0 !important;
}

.tabletwo .tablebody>tr>.precotd {
  font-size: 11px !important;
}